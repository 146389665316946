$name-circle-size: 35px;
$name-circle-primary: #575757;
$name-circle-secondary: #fff;
$name-circle-font-size: 16px;
$name-circle-radius: 50%;

$sign-in-height: 30px;
$sign-in-padding: 15px;
$sign-in-primary: #fff;
$sign-in-secondary: #575757;
$sign-in-font-size: 14px;
$sign-in-radius: 15px;

$icon-margin: 25px;
$icon-size: 25px;
$icon-primary: #575757;
$icon-secondary: #fff;
$icon-radius: 50%;

$redirect-font-size: 14px;
$redirect-font-color: inherit;

// tooltip follow each platform first

// hover & active follow each platform first

$popover-background: #fff;
$popover-background-dark: #222;

$info-height: 70px;
$info-line-height: 25px;
$info-fullname-font-size: 16px;
$info-email-font-size: 14px;
$info-email-font-color: #404040;
$menu-item-height: 45px;
$menu-item-hover-color: #f5f5f5;
$menu-item-icon-margin: 15px;
$menu-item-text-margin: 15px;
$menu-item-font-size: 14px;
$menu-item-font-color: #404040;
$menu-item-font-color-dark: #d0d0d0;

.zynet-account {
    &.dark {
        .zynet-account-sign-in {
            color: inherit;
            background-color: inherit;

            &:hover {
                color: $sign-in-secondary;
                background-color: $sign-in-primary;
            }
        }

        .zynet-account-name-circle {
            color: $name-circle-primary;
            background-color: $name-circle-secondary;
            border-color: $name-circle-secondary;
        }
    }

    .zynet-account-sign-in {
        display: none;
        align-items: center;
        justify-content: center;
        padding-right: $sign-in-padding;
        padding-left: $sign-in-padding;
        height: $sign-in-height;
        font-size: $sign-in-font-size;
        line-height: $sign-in-height;
        color: $sign-in-secondary;
        background-color: $sign-in-primary;
        border: 1px solid $sign-in-secondary;
        border-radius: $sign-in-radius;
        cursor: pointer;
        user-select: none;

        &:hover {
            color: $sign-in-primary;
            background-color: $sign-in-secondary;
        }
    }

    .zynet-account-name-circle {
        display: none;
        align-items: center;
        justify-content: center;
        width: $name-circle-size;
        height: $name-circle-size;
        font-size: $name-circle-font-size;
        line-height: $name-circle-size;
        text-align: center;
        color: $name-circle-secondary;
        background-color: $name-circle-primary;
        border: 1px solid $name-circle-primary;
        border-radius: $name-circle-radius;
        cursor: pointer;
    }

    &--nologin {
        .zynet-account-sign-in {
            display: inline-flex;
        }
    }

    &--login {
        .zynet-account-name-circle {
            display: inline-flex;
        }
    }
}

.zynet-account-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: hidden;
    z-index: 1001;

    &.show {
        visibility: visible;
    }

    &.light {
        color: $menu-item-font-color;

        .zynet-account-menu-list {
            .menu-item-icon {
                &--light {
                    display: inline-block;
                }
            }
        }
    }

    &.dark {
        color: $menu-item-font-color-dark;

        .zynet-account-popover {
            background-color: $popover-background-dark;

            .zynet-account-user-info {
                .name-circle .name {
                    color: $icon-primary;
                    background-color: $icon-secondary;
                    border: 1px solid $icon-secondary;
                }

                .info .email {
                    color: $menu-item-font-color-dark;
                }
            }
        }

        .zynet-account-menu-list {
            color: $menu-item-font-color-dark;

            a {
                &:hover {
                    color: initial;

                    path,
                    circle {
                        fill: initial;
                    }
                }
            }

            .menu-item-icon {
                &--dark {
                    display: inline-block;
                }
            }
        }
    }
}

.zynet-account-popover {
    position: relative;
    display: inline-block;
    margin: 8px;
    min-width: 300px;
    background-color: $popover-background;
    border-radius: 2px;
    z-index: 1000;
    box-shadow: 0 3px 6px #00000029;

    .zynet-account-user-info {
        display: flex;
        align-items: center;
        height: $info-height;
        border-bottom: 1px solid #ececec;
        box-sizing: border-box;

        .name-circle {
            display: flex;

            .name {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-left: $menu-item-icon-margin;
                width: $icon-size;
                height: $icon-size;
                font-weight: bold;
                line-height: initial;
                text-align: center;
                color: $icon-secondary;
                background-color: $icon-primary;
                border: 1px solid $icon-primary;
                border-radius: $icon-radius;
                box-sizing: border-box;
            }
        }

        .info {
            line-height: $info-line-height;

            .full-name {
                margin-right: $menu-item-text-margin;
                margin-left: $menu-item-text-margin;
                font-size: $info-fullname-font-size;
                font-weight: bold;
            }

            .email {
                margin-right: $menu-item-text-margin;
                margin-left: $menu-item-text-margin;
                font-size: $info-email-font-size;
                color: $info-email-font-color;
            }
        }
    }

    .zynet-account-menu-list {
        margin: 0;
        padding: 0;
        font-size: $menu-item-font-size;
        color: $menu-item-font-color;
        list-style: none;

        a {
            display: flex;
            align-items: center;
            height: $menu-item-height;
            text-decoration: none;
            color: inherit;
            box-sizing: border-box;

            &:hover {
                background-color: $menu-item-hover-color;
            }
        }

        .menu-item-icon {
            display: none;
            transform: translateY(2px);

            svg {
                // offset 5px for align
                margin-left: $menu-item-icon-margin + 5;
            }
        }

        .menu-item-extra-icon {
            margin-left: auto;

            svg {
                // offset 5px for align
                margin-right: $menu-item-icon-margin + 5;
            }
        }

        .menu-item-text {
            // offset 5px for align
            margin-left: $menu-item-text-margin + 5;
            font-size: $menu-item-font-size;
        }
    }
}
